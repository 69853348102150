import {
    FluidMainContent,
    FullMainContent,
    MainContainer,
    MainContent,
    MainLayoutProps,
} from "./main";

export const NoNavLayout = (props: MainLayoutProps): React.ReactElement => {
    return (
        <MainContainer>
            <MainContent>{props.content}</MainContent>
        </MainContainer>
    );
};

export const NoNavFullLayout = (props: MainLayoutProps): React.ReactElement => {
    return (
        <MainContainer>
            <FullMainContent>{props.content}</FullMainContent>
        </MainContainer>
    );
};

export const NoNavFluidLayout = (
    props: MainLayoutProps
): React.ReactElement => {
    return (
        <MainContainer>
            <FluidMainContent>{props.content}</FluidMainContent>
        </MainContainer>
    );
};

export const Empty = (props: MainLayoutProps): React.ReactElement => {
    return <>{props.content}</>;
};
