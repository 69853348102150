import { useSession, SessionContextValue } from "next-auth/react";
import { BaseGuard } from "./BaseGuard";
import { PermisisonTargets, PermissionAction, buildPermission } from "@/utils/permissions";
import { useGroupStore } from "../states/group";

export type AuthStatus = SessionContextValue["status"];

interface PermissionGuardProps {
    permission: { target: PermisisonTargets, action: PermissionAction }
    redirectTo: string;
    children: any;
}

export const PermissionGuard = (props: PermissionGuardProps) => {
    const { status } = useSession();
    const groupPermission = useGroupStore((state) => state.groupPermission);
    const perm = buildPermission(groupPermission)
    const shouldRedirect = () => !perm(props.permission.target, props.permission.action)

    return <BaseGuard status={status} data={status} shouldRedirect={shouldRedirect} {...props} />;
};
