import { GroupState } from "../states/group";

export enum PageGroupsConfigs {
    REQUIRED = "REQUIRED",
    PERMITTED = "PERMITTED",
}

type PageGroupMapType = {
    [key in PageGroupsConfigs]: {
        shouldRedirect: (groups: GroupState[]) => boolean;
        redirectTo: string;
    };
};

export const PageGroupMap: PageGroupMapType = {
    [PageGroupsConfigs.REQUIRED]: {
        shouldRedirect: (group) => group.length == 0,
        redirectTo: "/welcome",
    },
    [PageGroupsConfigs.PERMITTED]: {
        shouldRedirect: (group) => group.length > 0,
        redirectTo: "/dashboard",
    },
};
