import { AuthStatus } from "../organisms/AuthGuard";

export enum PageAuthConfigs {
    REQUIRED = "REQUIRED",
    PERMITTED = "PERMITTED",
}

type PageAuthMapType = {
    [key in PageAuthConfigs]: {
        shouldRedirect: (status: AuthStatus) => boolean;
        redirectTo: string;
    };
};

export const PageAuthMap: PageAuthMapType = {
    [PageAuthConfigs.REQUIRED]: {
        shouldRedirect: (status: AuthStatus) => status === "unauthenticated",
        redirectTo: "/auth/login",
    },
    [PageAuthConfigs.PERMITTED]: {
        shouldRedirect: (status: AuthStatus) => status === "authenticated",
        redirectTo: "/welcome",
    },
};
