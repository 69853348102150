import { SessionContextValue } from "next-auth/react";
import Router, { useRouter } from "next/router";
import { useEffect } from "react";
import { FullScreenLoading } from "../organisms/Loading";
import { NoNavLayout } from "./nonav";

export interface GuardProps<DataType> {
    shouldRedirect: (data: DataType) => boolean;
    redirectTo: string;
    children: any;
}

interface BaseGuard<DataType> extends GuardProps<DataType> {
    data: DataType;
    status: SessionContextValue["status"];
}

export function BaseGuard<DataType>({
    data,
    status,
    shouldRedirect,
    redirectTo,
    children,
}: BaseGuard<DataType>): JSX.Element {
    const { locale } = useRouter();
    const should_redirect = shouldRedirect(data);

    useEffect(() => {
        if (should_redirect && status !== "loading")
            Router.push(redirectTo, redirectTo, { locale });
    }, [should_redirect, redirectTo, locale, status]);

    if (!should_redirect && status !== "loading") return children;

    return <NoNavLayout content={<FullScreenLoading />} />;
}
