"use client";

import * as React from "react";

import {
    CommandDialog,
    CommandEmpty,
    CommandGroup,
    CommandInput,
    CommandItem,
    CommandList,
} from "@/components/ui/command";
import { CalendarCheck2, Gauge, Music4 } from "lucide-react";
import { useTranslation } from "next-i18next";

export function Command() {
    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation("common");

    const commands = [
        {
            name: t`Pages`,
            type: "heading",
            items: [
                {
                    name: t`Dashboard`,
                    type: "action",
                    icon: <Gauge className="mr-2 h-4 w-4 sm:mr-0 xl:mr-2" />,
                    action: () => (window.location.href = "/dashboard"),
                    items: [],
                },
                {
                    name: t`Events`,
                    type: "action",
                    icon: (
                        <CalendarCheck2 className="mr-2 h-4 w-4 sm:mr-0 xl:mr-2" />
                    ),
                    action: () => (window.location.href = "/events"),
                    items: [],
                },
                {
                    name: t`Songs`,
                    type: "action",
                    icon: <Music4 className="mr-2 h-4 w-4 sm:mr-0 xl:mr-2" />,
                    action: () => (window.location.href = "/song"),
                    items: [],
                },
            ],
        },
        {
            name: t`Actions`,
            type: "heading",
            items: [
                {
                    name: t`Events: New Event`,
                    type: "action",
                    icon: (
                        <CalendarCheck2 className="mr-2 h-4 w-4 sm:mr-0 xl:mr-2" />
                    ),
                    action: () => (window.location.href = "/events"),
                    items: [],
                },
                {
                    name: t`Songs: New Song`,
                    type: "action",
                    icon: <Music4 className="mr-2 h-4 w-4 sm:mr-0 xl:mr-2" />,
                    action: () => (window.location.href = "/song"),
                    items: [],
                },
            ],
        },
    ];

    React.useEffect(() => {
        const down = (e: KeyboardEvent) => {
            if (e.key === "j" && (e.metaKey || e.ctrlKey)) {
                e.preventDefault();
                setOpen((open) => !open);
            }
        };

        document.addEventListener("keydown", down);
        return () => document.removeEventListener("keydown", down);
    }, []);

    return (
        <CommandDialog open={open} onOpenChange={setOpen}>
            <CommandInput placeholder={t`Type a command or search...`} />
            <CommandList>
                <CommandEmpty>{t`No results found.`}</CommandEmpty>
                {commands.map((command) => {
                    if (command.type == "heading") {
                        return (
                            <CommandGroup
                                key={command.name}
                                heading={command.name}
                            >
                                {command.items.map((item) => (
                                    <CommandItem
                                        key={item.name}
                                        onSelect={item.action}
                                    >
                                        {item.icon}
                                        <span>{item.name}</span>
                                    </CommandItem>
                                ))}
                            </CommandGroup>
                        );
                    }
                })}
            </CommandList>
        </CommandDialog>
    );
}
