// _app.tsx helpers

import {
    PermisisonTargets,
    PermissionAction,
    buildPermission,
} from "@/utils/permissions";
import { PageAuthConfigs, PageAuthMap } from "../consts/auth";
import { PageGroupMap, PageGroupsConfigs } from "../consts/group";
import { AuthGuard } from "./AuthGuard";
import { GroupGuard } from "./GroupGuard";
import { MainLayoutProps } from "./main";
import { useGroupStore } from "../states/group";
import { PermissionGuard } from "./PermissionGuard";

export const AuthGateChecker: React.FC<{
    status: PageAuthConfigs | undefined;
    children: React.ReactNode;
}> = ({ status, children }) => {
    if (status) {
        return (
            <AuthGuard
                shouldRedirect={
                    PageAuthMap[status as PageAuthConfigs].shouldRedirect
                }
                redirectTo={PageAuthMap[status as PageAuthConfigs].redirectTo}
            >
                {children}
            </AuthGuard>
        );
    }
    return <>{children}</>;
};

export const GroupGateChecker: React.FC<{
    status: PageGroupsConfigs | undefined;
    children: React.ReactNode;
}> = ({ status, children }) => {
    if (status) {
        return (
            <GroupGuard
                shouldRedirect={
                    PageGroupMap[status as PageGroupsConfigs].shouldRedirect
                }
                redirectTo={
                    PageGroupMap[status as PageGroupsConfigs].redirectTo
                }
            >
                {children}
            </GroupGuard>
        );
    }

    return <>{children}</>;
};

export const LayoutChecker: React.FC<{
    status: React.FC<MainLayoutProps> | undefined;
    children: React.ReactNode;
}> = ({ status, children }) => {
    if (status) {
        let LayoutComponent: React.FC<MainLayoutProps> = status;
        return <LayoutComponent content={<>{children}</>} />;
    }

    return <>{children}</>;
};

export const PermissionChecker: React.FC<{
    permission: { target: PermisisonTargets; action: PermissionAction };
    children: React.ReactNode;
}> = ({ permission, children }) => {
    if (permission) {
        return (
            <PermissionGuard permission={permission} redirectTo={"/dashboard"}>
                {children}
            </PermissionGuard>
        );
    }
    return <>{children}</>;
};
