import { createTRPCNext } from "@trpc/next";
import { httpBatchLink } from "@trpc/client";
import type { AppRouter } from "../pages/api/trpc/[trpc]";
import { inferRouterInputs, inferRouterOutputs } from "@trpc/server";

export type RouterInput = inferRouterInputs<AppRouter>;
export type RouterOutput = inferRouterOutputs<AppRouter>;

function getBaseUrl() {
    if (typeof window !== "undefined")
        // browser should use relative path
        return "";
    if (process.env.VERCEL_URL)
        // reference for vercel.com
        return `https://${process.env.VERCEL_URL}`;
    // if (process.env.RENDER_INTERNAL_HOSTNAME)
    //     // reference for render.com
    //     return `http://${process.env.RENDER_INTERNAL_HOSTNAME}:${process.env.PORT}`;
    // assume localhost
    return `http://localhost:${process.env.PORT || 3000}`;
}
export const trpc = createTRPCNext<AppRouter>({
    config({ ctx }) {
        return {
            links: [
                httpBatchLink({
                    /**
                     * If you want to use SSR, you need to use the server's full URL
                     * @link https://trpc.io/docs/ssr
                     **/
                    url: `${getBaseUrl()}/api/trpc`,
                    headers: () => {
                        if (ctx?.req) {
                            // on ssr, forward client's headers to the server
                            const { connection: _connection, ...headers } =
                                ctx.req.headers;
                            return {
                                ...headers,
                                // Optional: inform server that it's an SSR request
                                "x-ssr": "1",
                            };
                        }
                        return {};
                    },
                }),
            ],
            /**
             * @link https://react-query-v3.tanstack.com/reference/QueryClient
             **/
            // queryClientConfig: { defaultOptions: { queries: { staleTime: 60 } } },
        };
    },
    ssr: true,
    responseMeta({ clientErrors, ctx }) {
        if (clientErrors.length) {
            // propagate first http error from API calls
            return {
                status: clientErrors[0].data?.httpStatus ?? 500,
            };
        }
        // cache full page for 1 day + revalidate once every second
        const ONE_DAY_IN_SECONDS = 60 * 60 * 24;
        return {
            "Cache-Control": `s-maxage=1, stale-while-revalidate=${ONE_DAY_IN_SECONDS}`,
        };
    },
});
