import Head from "next/head";
import { SessionProvider } from "next-auth/react";
import { appWithTranslation } from "next-i18next";

import {
    AuthGateChecker,
    GroupGateChecker,
    LayoutChecker,
    PermissionChecker,
} from "@/components/organisms/app";
import { trpc } from "@/utils/trpc";
import {
    Toast,
    ToastClose,
    ToastDescription,
    ToastProvider,
    ToastTitle,
    ToastViewport,
} from "@/components/ui/toast";
import { Toaster } from "@/components/ui/sonner";
import { useToast } from "@/components/ui/use-toast";
import { Command } from "./Command";
import { ThemeProvider } from "../theme-provider";

function CustomApp(props: any) {
    const { toasts } = useToast();

    return (
        <SessionProvider session={props.pageProps.session}>
            <Command />
            <Head>
                <meta
                    name="viewport"
                    content="initial-scale=1, width=device-width"
                />
            </Head>
            <ThemeProvider
                attribute="class"
                defaultTheme="light"
                enableSystem
                disableTransitionOnChange
                forcedTheme={props.Component.theme || null}
            >
                <PermissionChecker permission={props.Component.permission}>
                    <AuthGateChecker status={props.Component.auth}>
                        <GroupGateChecker status={props.Component.group}>
                            <LayoutChecker status={props.Component.layout}>
                                <props.Component {...props.pageProps} />
                            </LayoutChecker>
                        </GroupGateChecker>
                    </AuthGateChecker>
                </PermissionChecker>
            </ThemeProvider>
            <Toaster />
            <ToastProvider>
                {toasts.map(function ({
                    id,
                    title,
                    description,
                    action,
                    icon,
                    ...props
                }) {
                    return (
                        <Toast key={id} {...props}>
                            <div className="flex gap-2">
                                {icon && <div>{icon}</div>}
                                <div className="grid gap-1">
                                    {title && <ToastTitle>{title}</ToastTitle>}
                                    {description && (
                                        <ToastDescription>
                                            {description}
                                        </ToastDescription>
                                    )}
                                </div>
                            </div>
                            {action}
                            <ToastClose />
                        </Toast>
                    );
                })}
                <ToastViewport />
            </ToastProvider>
        </SessionProvider>
    );
}

export default trpc.withTRPC(appWithTranslation(CustomApp));
