import { useSession } from "next-auth/react";
import { useGroupStore, GroupState } from "../states/group";
import { BaseGuard, GuardProps } from "./BaseGuard";
import shallow from "zustand/shallow";

export const GroupGuard = (props: GuardProps<GroupState[]>) => {
    const { data, status } = useSession();
    const [setGroup, groupOid] = useGroupStore(
        (state) => [state.setGroup, state.groupOid],
        shallow
    );
    const groups = data?.groups as any[];
    let guard_data = [];
    if (groups && groups.length > 0) {
        guard_data = groups;
        if (groupOid == "") {
            setGroup(groups[0]);
        }
    }

    return <BaseGuard status={status} data={guard_data} {...props} />;
};
