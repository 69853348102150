import { useSession, SessionContextValue } from "next-auth/react";
import { BaseGuard } from "./BaseGuard";

export type AuthStatus = SessionContextValue["status"];

interface AuthGuard {
    shouldRedirect: (status: AuthStatus) => boolean;
    redirectTo: string;
    children: any;
}

export const AuthGuard = (props: AuthGuard) => {
    const { status } = useSession();

    return <BaseGuard status={status} data={status} {...props} />;
};
